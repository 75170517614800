<template xmlns:MultiPerson="http://www.w3.org/1999/html">
    <div class="receiveInterview-container">
        <BreadcrumbNav :page-name="$t('myInterview.myInterviewDetail')"/>
        <div class="main">
            <div class="people-wrap">
                <div class="people-info fl">
                    <img :src="details_data.avatar" alt="" class="head-icon fl">
                    <div class="people-info-text fl">
                        <h1 class="over">{{ details_data.name }}</h1>
                        <h2 class="over">{{ details_data.company_name }} · {{ details_data.position }}</h2>
                    </div>
                </div>
                <div class="btn-group fl">
                    <CustomButton @click.native="$router.push({
            path: '/user/index',query:{userType:2,talentId:details_data.talent_id,}})" class="fl" type="black">
                        {{ $t("project.buyNow") }}<!--立即购买-->
                    </CustomButton>
                    <!--<CustomButton class="fl" type="white" style="margin-left: 26px">取消关注</CustomButton>-->
                    <CustomButton
                            @click.native="attention(details_data.followed, details_data.talent_id, details_data.name)"
                            class="fl" style="margin-left: 26px"
                            type="black"
                            v-if="this.details_data.followed == 0">{{$t('myProject.immediateAttention')}}
                        <!--立即关注--></CustomButton>
                    <CustomButton
                            @click.native="attention(details_data.followed, details_data.talent_id, details_data.name)"
                            class="fl" style="margin-left: 26px"
                            type="white"
                            v-else>{{$t('myProject.unfollow')}}<!--取消关注--></CustomButton>
                </div>
            </div>

            <!--------------------已取消预约START---------------->
            <div class="state6" v-if="details_data.progress==4">
                <h1>{{$t('myPublishedProjects.cancelledAppointment')}}<!--您已取消预约--></h1>
                <p>{{$t('vipRecommend.publisherCancelsReason')}}<!--取消访谈原因--> ：{{ details_data.cancel_reason }}</p>
            </div>
            <!--------------------已取消预约END-------------------->
            <!--------------------已取消预约START---------------->
            <div class="state6" v-if="details_data.progress==3">
                <h1>{{$t('myInterviewDetail.haveInvitation')}}<!--您已接受邀请--></h1>
                <p>{{$t('myProject.interviewTime')}}<!--访谈时间--> ：{{ details_data.invitation_time }}</p>
            </div>
            <!--------------------已取消预约END-------------------->
            <!--------------------已取消预约START---------------->
            <div class="state6" v-if="details_data.progress==2">
                <h1>{{$t('myPublishedProjects.declinedInvitation')}}<!--您已拒绝邀请--></h1>
                <p>{{$t('myProject.interviewTime')}}<!--访谈时间--> ：{{ details_data.invitation_time }}</p>
                <p>{{$t('myPublishedProjects.reasonInvitation')}}<!--拒绝原因--> ：{{ details_data.reject_reason }}</p>
            </div>
            <div class="state6" v-if="details_data.progress==5">
                <h1>访谈结束</h1>
                <p>这个时间段接受您的预约申请</p>
                <p>{{ details_data.invitation_time }}</p>
            </div>
            <div class="state6" v-if="details_data.progress==1">
                <!--<h1>预约时间</h1>-->
                <p>{{$t('myProject.interviewTime')}}<!--访谈时间--> ：{{ details_data.invitation_time }}</p>
            </div>
            <!--------------------已取消预约END-------------------->
            <MultiPerson :list="details_data.rosterList" style="border-top:none"/>

            <!--------------------待受邀人反馈START---------------->
            <div class="state6" v-if="details_data.progress==1">
                <div class="leave-message">
                    <h1>{{ $t('vipRecommend.messageAboutBookingToCustomerService') }}<!--关于预约给客服的留言--></h1>
                    <textarea :placeholder="$t('publish.input')/*请输入*/" cols="30" id="" name="" rows="10"
                              v-model="messageContent">
          </textarea>
                </div>
                <div class="is-btn-group">
                    <CustomButton @click.native="$refs.agreeForInvitee.openDialog" class="fl" type="black">
                        {{$t('myInterviewDetail.acceptInvitation')}}<!--接受邀请-->
                    </CustomButton>
                    <CustomButton @click.native="$refs.disagreeForInvitee.openDialog" class="fl"
                                  style="margin-left:17px; " type="black">
                        {{$t('myInterviewDetail.declinedInvitation')}}<!--拒绝邀请-->
                    </CustomButton>
                </div>
            </div>
            <!--------------------待受邀人反馈END-------------------->
            <!--------------------受邀人拒绝START---------------->
            <div class="state6" v-if="details_data.progress==2">
                <div class="s-leave-message">
                    <!--翻译：关于预约给客服的留言-->
                    <h2>{{ $t('vipRecommend.messageAboutBookingToCustomerService') }}</h2>
                    <p class="over4">{{ details_data.invite_message }}</p>
                </div>
            </div>
            <!--------------------受邀人拒绝END-------------------->
            <!--------------------受邀人同意START---------------->
            <div class="state6" v-if="details_data.progress==3">
                <div class="s-leave-message">
                    <!--翻译：关于预约给客服的留言-->
                    <h2>{{ $t('vipRecommend.messageAboutBookingToCustomerService') }}</h2>
                    <p class="over4">{{ details_data.invite_message }}</p>
                </div>
                <div class="cancel-yy" v-if="details_data.isShow == 1">
                    <CustomButton @click.native="$refs.cancelAppointment.openDialog" class="btn" type="white">
                        {{$t('myProject.cancelReservation')}}
                    </CustomButton>
                    <span>{{$t("bulletFrame.appointmentTwoHours")}}</span>
                </div>
                <div class="cancel-yy" v-if="details_data.isFanTan == 1">
                    <CustomButton @click.native="$router.push({path:'/videoRoom',
                          query:{interviewId:details_data.interview_id,type:4,isPublisher:1}})" class="btn"
                                  type="black">
                        {{$t('myProject.interview')}}
                    </CustomButton>
                    <span>{{$t("bulletFrame.appointmentTwoHours")}}</span>
                </div>
            </div>
            <!--------------------受邀人同意END-------------------->


            <!--    沟通话题-->
            <div class="talk-topic">
                <h1>{{$t("myPublishedProjects.communTopic")}}<!--沟通话题--></h1>
                <img alt="" src="@/assets/images/cheche.png">
                <div class="title over">{{ details_data.project_name }}</div>
                <p>{{ details_data.project_intro }}</p>
                <!--<div class="source">
                  <span>A</span>
                  <span>轮融资</span>
                </div>-->
            </div>
            <!--      项目详情-->
            <div class="project-detail">
                <h1>{{$t('project.details')}}<!--项目详情--></h1>
                <div v-html="details_data.details"></div>
            </div>
        </div>
        <AgreeForInvitee ref="agreeForInvitee"/>
        <DisagreeForInvitee ref="disagreeForInvitee"/>
        <CancelAppointment ref="cancelAppointment"/>
    </div>
</template>

<script>
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import CustomButton from "@/components/my_project/vip_private_recommendation/CustomButton";
    import AppointmentTime from "@/components/my_project/my_interview/receive_interview/AppointmentTime";
    import RecommendReason from "@/components/my_project/my_interview/receive_interview/RecommendReason";
    import {
        multiThreeProDetails,
        multiThreeInviteeFeedback,
        multiThreeInviteeCancel
    } from "@/api/myProjectApi/interviewApi"
    import MultiPerson from "@/components/my_project/MultiPersonFreeCommunication";
    import AgreeForInvitee from "@/components/dialog/AgreeForInvitee";
    import DisagreeForInvitee from "@/components/dialog/DisagreeForInvitee";
    import CancelAppointment from "@/components/dialog/CancelAppointment";
    import {followTalent} from "@/api/memberApi";


    export default {
        name: "receiveInterview",
        data() {
            return {
                delivery_data: {},
                details_data: {},
                times1: {},
                messageContent: '',
            };
        },
        components: {
            BreadcrumbNav,
            CustomButton,
            AppointmentTime,
            RecommendReason,
            MultiPerson,
            AgreeForInvitee,
            DisagreeForInvitee,
            CancelAppointment
        },
        props: {},
        mounted() {
            this.delivery_data = this.$route.query;
            this.getPageData();
        },
        methods: {
            //关注
            attention(type, id, name) {
                let formData = {
                    operateType: type,
                    talentId: id,
                    talentName: name,
                    token: localStorage.getItem('token')
                }
                followTalent(formData).then((res) => {
                    if (res.code == 200) {
                        if (type == 1) {
                            this.details_data.followed = 0
                            this.$message.success(this.$t("myProject.followMsg.unfollowSuccess"))
                        } else {
                            this.details_data.followed = 1
                            this.$message.success(this.$t("myProject.followMsg.followSuccess"))
                        }
                    }
                })
            },
            // 页面进度数据
            async getPageData() {
                let formData = {
                    projectId: this.$route.params.id,
                    joinId: this.$route.query.join_id,
                    token: localStorage.getItem("token"),
                };
                let res = await multiThreeProDetails(formData);
                if (res.code == 200) {
                    this.details_data = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            },
            // 受邀人反馈
            async goFeedback(feedback, form) {
                let formData = {
                    token: localStorage.getItem("token"),
                    joinId: this.$route.query.join_id,
                    messageContent: this.messageContent,
                    feedback: feedback,
                    rejectReason: form.reason,
                    mobileCode: form.mobileCode,
                    mailBox: form.mail,
                    mobileNumber: form.phone,
                    whatsappCode: form.whatsappCode,
                    whatsApp: form.whatsApp,
                };
                let res = await multiThreeInviteeFeedback(formData);
                if (res.code == 200) {
                    this.$message.success(res.msg);
                    await this.getPageData();
                } else {
                    this.$message.error(res.msg);
                }
            },
            // 取消预约
            async cancelAppointment(type, form) {
                let formData = {
                    token: localStorage.getItem("token"),
                    joinId: this.$route.query.join_id,
                    cancelReason: form.reason,
                    mailBox: form.mail,
                    mobileCode: form.mobileCode,
                    mobileNumber: form.phone,
                    whatsappCode: form.whatsappCode,
                    whatsApp: form.whatsApp,
                };
                let res = await multiThreeInviteeCancel(formData);
                if (res.code == 200) {
                    this.$message.success(res.msg);
                    await this.getPageData();
                } else {
                    this.$message.error(res.msg);
                }
            },
        },
    }
</script>

<style scoped>
    .state6 h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    .state6 p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
        margin-top: 10px;
    }

    .receiveInterview-container {
        background-color: #FFFFFF;
    }

    .main {
        width: 1200px;
        padding-top: 14px;
        padding-bottom: 24px;
        margin: 0 auto;
    }

    .people-wrap {
        padding: 38px 0;
        overflow: hidden;
    }

    .people-wrap .head-icon {
        width: 67px;
        height: 67px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
    }

    .people-info-text {
        margin-left: 13px;
        min-width: 160px;
        max-width: 300px;
    }

    .people-info-text > h1 {
        font-size: 17px;
        font-family: BentonSans;
        font-weight: 500;
        color: #000000;
        margin-top: 8px;
    }

    .people-info-text > h2 {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
        margin-top: 13px;
        margin-left: 4px;
    }

    .people-wrap .btn-group {
        margin-top: 4px;
        margin-left: 136px;
    }

    hr {
        background-color: #E5E5E5;;
    }

    .talk-topic {
        margin-top: 62px;
    }

    .talk-topic > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    .talk-topic > img {
        width: 695px;
        height: 460px;
        background: rgba(0, 0, 0, 0);
        border-radius: 4px;
        margin-top: 24px;
    }

    .talk-topic > .title {
        width: 695px;
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 46px;
        color: #000000;
        margin-top: 24px;
    }

    .talk-topic > p {
        width: 468px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 29px;
        color: #000000;
        margin-top: 26px;
    }

    .talk-topic > .source {
        margin-top: 16px;
    }

    .talk-topic > .source > span:first-child {
        font-size: 22px;
        font-family: BentonSans;
        font-weight: 500;
        color: #000000;
        vertical-align: bottom;
        margin-right: 9px;
    }

    .talk-topic > .source > span:nth-child(2) {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        vertical-align: bottom;
    }

    .project-detail {
        margin-top: 47px;
    }

    .project-detail > h1 {

        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    .project-detail > img {
        margin-top: 42px;

        width: 688px;
    }

    .reason {
        margin-top: 16px;
    }

    .reason > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .reason > p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
        margin-top: 10px;
    }

    .is-btn-group {
        margin-top: 38px;
        overflow: hidden;
    }

    .leave-message {
        margin-top: 55px;

    }

    .leave-message > h1 {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;

    }

    .leave-message textarea {
        width: 695px;
        height: 117px;
        background: #FAFAFA;
        border-radius: 4px;
        margin-top: 21px;
        resize: none;
        padding: 17px 26px;
        box-sizing: border-box;
    }

    .is-btn-group {
        margin-top: 40px;
        overflow: hidden;
    }

    .s-leave-message {
        margin-top: 30px;
    }

    .s-leave-message > h2 {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .s-leave-message > p {
        margin-top: 7px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
    }

    .cancel-yy {
        margin-top: 54px;
    }

    .cancel-yy .btn {
        display: inline-block;
    }

    .cancel-yy > span {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 50px;
        color: #949494;
        margin-left: 33px;
    }
</style>
